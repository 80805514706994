import React from 'react';

import Moment from 'moment';

import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import SEO from 'components/seo';
import PrivacyPolicyEn from '@youship/legal/privacy-en';
import PrivacyPolicyPt from '@youship/legal/privacy-pt';
import Privacy from '@youship/components/privacy';

const lastModifiedDate = Moment().format('MMMM DD, YYYY');

const PrivacyPage = ({ pageContext: { locale } }) => {
  let termsContent = null;

  switch (locale) {
    case 'pt':
      termsContent = <PrivacyPolicyPt />;
      break;
    case 'en':
    default:
      termsContent = <PrivacyPolicyEn />;
  }

  return (
    <Layout locale={locale}>
      
      <SEO title="footer.copyright.links.privacy_policy" />
      <LegalLayout
        lastModifiedDate={lastModifiedDate}
        title="footer.copyright.links.privacy_policy"
      >
        <Privacy 
          locale={locale}
        />  
      </LegalLayout>
    </Layout>
  );
};

export default PrivacyPage;
